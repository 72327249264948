::-webkit-scrollbar {
  height: 0px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 0px;
  /* width of vertical scrollbar */
  /* border: 1px solid #d5d5d5; */
}

.webhook_list_container {
  display: none;
}

.divider:last-child {
  display: none;
}

.webhook_heading {
  font-weight: bold;
  font-size: 24px;
  color: #3086EC
}

.webhook_subheading {
  font-size: 14px;
}

.webhook_lottie_delete_web {
  display: block;
}

.webhook_lottie_delete_mob {
  display: none;
}

.delete_webhook_text {
  font-size: 16px;
}

.active_webhook_status {
  color: #11BD60;
  background: #DDFFDE;
  border-radius: 16px;
  padding: 5px 10px;
  width: fit-content;
}

.inactive_webhook_status {
  color: #FF5A5A;
  background: #FFE5E5;
  border-radius: 16px;
  padding: 5px 10px;
  width: fit-content;
}

@media screen and (max-width: 600px) {

  .webhook_list_container {
    display: flex;
  }

  .delete_webhook_text {
    font-size: 14px;
  }

  .webhook_list_container>ul {
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border-radius: 6px;
  }

  .webhook_table_container {
    display: none;
  }

  .webhook_heading {
    font-size: 16px;
  }

  .webhook_subheading {
    font-size: 12px;
  }

  .webhooks_action {
    flex-direction: row;
  }

  .active_webhook_status {
    font-size: 10px;
    color: #3BB900;
    background: #ECFBE6;
    border-radius: 16px;
    padding: 5px 10px;
    width: fit-content;
  }

  .inactive_webhook_status {
    font-size: 10px;
    color: #F49200;
    background: #FFF6E8;
    border-radius: 16px;
    padding: 5px 10px;
    width: fit-content;
  }

  .webhook_details {
    width: 75%;
    word-break: break-all;
  }

  #webhook-menu .MuiMenu-list {
    padding: 0;
  }

  #webhook-menu .MuiMenu-list .MuiMenuItem-root {
    padding-top: 0;
    padding-bottom: 0;
    min-height: 36px;
  }

  .webhook_lottie_delete_web {
    display: none;
  }

  .webhook_lottie_delete_mob {
    display: block;
  }

}