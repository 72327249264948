.create_apikey_button_container{
    margin-top: 10px;
}

.apikey_heading{
    font-weight: bold;
    font-size: 24px;
}

.apikey_subheading{
    font-size: 14px;
}

.apikey_list_container{
    display: none;
}

.divider:last-child{
    display: none;
}

.generate_apikey_button_container{
    margin: 16px;
}

#apikeys-menu .MuiMenu-root .MuiMenu-paper {
    background-color: #ffffff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    padding: 0;
}

.lottie_delete_web{
    display: block;
}

.lottie_delete_mob{
    display: none;
}

.delete_apikey_text{
    font-size: 16px;
}

.generate_key_lottie{
    width: 250px;
}

@media screen and (max-width: 900px) {

    .create_apikey_button_container{
        justify-content: flex-end;
    }

}

@media screen and (max-width: 600px) {

    .apikey_heading{
        font-size: 16px;
    }

    .apikey_subheading{
        font-size: 12px;
    }

    .create_apikey_button, .generate_apikey_button, .extend_generate_key{
        padding: 8px 14px;
        font-size: 12px;
    }

    .generate_apikey_button_container, .extend_generate_key_container{
        justify-content: center;
    }

    .extend_generate_key .MuiSvgIcon-root{
        font-size: 20px;
    }

    .extend_generate_key_box{
        padding-right: 0;
    }

    .apikey_table_container{
        display: none;
    }

    .apikey_list_container{
        display: flex;
        flex-direction: column;
    }
    
    .apikey_list_container > ul {
        box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
        border-radius: 6px;
    }

    #apikeys-menu .MuiMenu-list {
        padding: 0;
    }

    #apikeys-menu .MuiMenu-list .MuiMenuItem-root {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 36px;
    }

    .generate_key_button_container{
        align-items: flex-end;
    }

    .generate_key_button{
        max-height: 36px;
    }

    .delete_lottie_image{
        height: 70px;
        width: 70px;
    }

    .lottie_delete_web{
        display: none;
    }

    .lottie_delete_mob{
        display: block;
    }
    
    .delete_apikey_text{
        font-size: 14px;
    }

    .generate_key_lottie{
        width: 180px;
    }
}