.features_container{
    padding: 40px 0;
    background: white;
    color: black;
}

.pricing_title{
    font-size: 30px;
    text-align: center;
    margin-bottom: 20px;
}

.pricing_subtitle{
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #878282;
}

.contact_link{
    color: #3087EC;
    text-decoration: none;
}
.contact_link:hover {
    text-decoration: underline; 
    cursor:pointer;
}

@media screen and (max-width: 600px) {

    .features_container{
        padding: 20px 0;
    }

    .pricing_title{
        font-size: 24px;
    }
}

