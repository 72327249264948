.pricing_button{
    color: #000;
    font-size: 16px;
    padding: 0 20px 0 40px;
    font-weight: 300;
}

.pricing_button:hover{
    background: white;
}

.navbar_options_container .MuiPaper-root.MuiMenu-paper{
    background: white;
    margin-left: 40px;
}

.navbar_options_icon{
    padding: 0 10px;
    padding-top: 4px;
}

.navbar_options_text{
    font-size: 14px;
    color: #1A75FF;
    text-align: left;
}

.navbar_options_subtext{
    font-size: 12px;
    color:#C4C4C4;
}

.navbar_options_container .MuiList-root{
    padding: 5px;
}

.navbar_options_container .MuiMenuItem-root{
    padding: 5px;
}

.navbar_options_container .MuiMenuItem-root:hover{
    background: #f6f6f6;
}

.navbar {
    /* navbar styles */
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: none;
  }
  
  .navbar.shadow {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }
  
  
  
  
  
  
